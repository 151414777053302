
:root {
	--c-grey-100: #f4f6f8;
	--c-grey-300: #b2b2b2;
	--c-grey-400: #7b7b7b;
	--c-grey-500: #3d3d3d;

	--c-blue-500: #688afd;
}

.timeline {
	width: 95%;
	display: flex;
	flex-direction: column;
	padding: 0 0 0 32px;
	border-left: 2px solid var(--primary);
}

.timeline-item-wrapper {
	width: 100%;
}

.timeline-item {
	display: flex;
	gap: 24px;
	& + * {
		margin-top: 24px;
	}
	& + .extra-space {
		margin-top: 48px;
	}
}

.timeline-item-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin-left: -52px;
	flex-shrink: 0;
	overflow: hidden;
	box-shadow: 0 0 0 1px var(--primary);
	background-color: #fff;
}

.timeline-item-description {
	display: flex;
	padding-top: 5px;
}

.timeline-item-subdescription {
	display: flex;
	padding-left: 10px;
	font-size: 15px;
	color: var(--neutral)
}

.timeline-item-content {
	border-radius: 6px;
	padding-left: 16px;
	font-size: 1rem;
}

.comment {
	margin-top: 12px;
	color: var(--c-grey-500);
	border: 1px solid var(--primary);
	box-shadow: 0 4px 4px 0 var(--c-grey-100);
	border-radius: 6px;
	padding: 16px;
	font-size: 1rem;
}

@media print {
	.timeline-item-icon {
		width: 30px;
		height: 30px;
		margin-left: -48px;
	}

	.timeline-item {
		gap: 14px;
		& + * {
			margin-top: 1cqb;
		}
		& + .extra-space {
			margin-top: 48px;
		}
	}
}