.card {
  padding: 10px 10px 10px 10px;
	box-sizing: border-box;
}
.market-card {
	box-sizing: border-box;
}

.card-report {
  border: 2px solid var(--primary);
  border-radius: 3mm 3mm 3mm 3mm;
}

.car-market {
  background-color: white;
}

@media (max-width: 620px) {
  .card {
    width: 100%;
  }
  .market-card {
    width: 100%;
  }
}

.quality-market {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.quality-market.none {
  background-color: var(--neutral);
}
.quality-market.good {
  background-color: var(--neutral);
}
.quality-market.low {
  background-color: var(--valid);
}
.quality-market.high {
  background-color: var(--warning);
}