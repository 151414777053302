/* CSS */
.button-t2y {
  border: 1px solid var(--none);
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  cursor: pointer;
  font-family: "Amazon Ember",sans-serif;
  line-height: 1.625;
  padding: 0 10px 0 11px;
  text-align: center;
  vertical-align: middle;
}

.button-t2y:hover {
  background-color: var(--none);
}

.button-t2y:focus {
  border-color: var(--primary);
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  outline: 0;
}

.primary-btn {
  background-color: var(--primary);
  padding: 10px 5px;
}
.primary-btn:hover {
  background-color: var(--primaryDark);
}

.secondary-btn {
  border-color: var(--primary);
  border-width: 2px;
  background-color: var(--white);
  padding: 10px 0;
}
.secondary-btn:hover {
  border-color: var(--primaryDark);
}
.secondary-btn:hover .icon {
  fill: var(--primaryDark);
}

.neutral-btn {
  background-color: var(--white);
  padding: 10px 0;
}
.neutral-btn:hover {
  border-color: var(--neutral);
}
.neutral-btn:hover .icon {
  fill: var(--primaryDark);
}