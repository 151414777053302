.t2y-underline {
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
}
.t2y-underline:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: var(--primary);
}
.t2y-underline:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 300px;
  background-color: var(--primary);
}


@media print {
  /* Styles spécifiques à l'impression avec utilisation de la variable personnalisée */
  .t2y-underline {
    text-decoration: underline;
    text-decoration-color: var(--primary);
  }
}