img.vehicleIcon {
	fill: var(--neutral);
}
img.vehicle-small {
	width: 20px;
	height: 20px;
}
img.vehicle-medium {
	width: 30px;
	height: 30px;
}

img.vehicle-large {
	width: 80px
}

@media print {
	img.vehicle-media-small {
		width: 20px;
		height: 20px;
	}
	img.vehicle-media-medium {
		width: 30px;
		height: 30px;
	}

	img.vehicle-media-large {
		width: 80px
	}
}