
.bufferedImageDiv {
	background-color: var(--validLight);
	padding: 3px;
	color: var(--valid);
	border-radius: 15px;
	position: absolute;
	bottom: 10px;
	right: 10px;
}

.bufferedImageIcon {
	fill: var(--valid);
	padding-right: 5px;
}

img.bufferedImageSize {
	width: 250px;
}
