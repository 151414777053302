
/* ~~~~~~ Size ~~~~~~ */
svg.icon-xtr-small {
	width: 15px;
	height: 15px;
}
svg.icon-small {
	width: 20px;
	height: 20px;
}
svg.icon-medium {
	width: 30px;
	height: 30px;
}

@media print {
	svg.icon-media-xtr-small {
		width: 15px;
		height: 15px;
	}
	svg.icon-media-small {
		width: 20px;
		height: 20px;
	}
	svg.icon-media-medium {
		width: 30px;
		height: 30px;
	}
}

/* ~~~~~~ End Size ~~~~~~ */
/* ~~~~~~ Color ~~~~~~ */
svg.icon {
	fill: var(--neutral);
}
svg.icon-white {
	fill: var(--white)
}
svg.icon-black {
	fill: var(--black)
}
svg.icon-none {
	fill: var(--none)
}
svg.icon-default {
	fill: var(--neutral)
}
svg.icon-neutralLight {
	fill: var(--neutralLight)
}
svg.icon-primary {
	fill: var(--primary)
}
svg.icon-primaryDark {
	fill: var(--primaryDark)
}
svg.icon-white {
	fill: var(--white)
}
svg.icon-second {
	fill: var(--second)
}
svg.icon-valid {
	fill: var(--valid)
}
svg.icon-validLight {
	fill: var(--validLight)
}
svg.icon-warning {
	fill: var(--warning)
}

/**** En accord avec les maquette ****/
svg.icon-baseWhite { fill: var(--baseWhite) }
svg.icon-backGround { fill: var(--backGround) }
svg.icon-blue { fill: var(--blue) }
svg.icon-stone100 { fill: var(--stone100) }
svg.icon-stone200 { fill: var(--stone200) }
svg.icon-stone300 { fill: var(--stone300) }
svg.icon-stone500 { fill: var(--stone500) }
svg.icon-stone600 { fill: var(--stone600) }
svg.icon-stone900 { fill: var(--stone900) }
svg.icon-yellow400 { fill: var(--yellow400) }
/**** En accord avec les maquette ****/
/* ~~~~~~ End Color ~~~~~~ */
/* rating icon */
svg.icon-primary.leaf {
	fill: #09AE23
}
svg.icon-primary.star {
	fill: #FFD633
}
