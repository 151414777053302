.offerText {
	opacity: 0;
}

.offerText.show {
	opacity: 1;
	transition: font-size 0.5s, opacity 1s ease, visibility 0s 2s
}

.viewFontNoSpace {
	font-size: 0 !important;
	line-height: 0 !important;
}
